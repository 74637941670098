exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-exhibitions-future-js": () => import("./../../../src/pages/exhibitions-future.js" /* webpackChunkName: "component---src-pages-exhibitions-future-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-template-artist-bio-js": () => import("./../../../src/template/artist-bio.js" /* webpackChunkName: "component---src-template-artist-bio-js" */),
  "component---src-template-artist-exhibitions-js": () => import("./../../../src/template/artist-exhibitions.js" /* webpackChunkName: "component---src-template-artist-exhibitions-js" */),
  "component---src-template-artist-press-js": () => import("./../../../src/template/artist-press.js" /* webpackChunkName: "component---src-template-artist-press-js" */),
  "component---src-template-artist-profile-js": () => import("./../../../src/template/artist-profile.js" /* webpackChunkName: "component---src-template-artist-profile-js" */),
  "component---src-template-exhibit-js": () => import("./../../../src/template/exhibit.js" /* webpackChunkName: "component---src-template-exhibit-js" */),
  "component---src-template-exhibition-archive-year-js": () => import("./../../../src/template/exhibition-archive-year.js" /* webpackChunkName: "component---src-template-exhibition-archive-year-js" */),
  "component---src-template-news-archive-year-js": () => import("./../../../src/template/news-archive-year.js" /* webpackChunkName: "component---src-template-news-archive-year-js" */)
}

